function getAppStatus(){
    if(window.location.origin === 'https://app.linkbuildinghq.com'){
        return 'production';
    }else if(window.location.origin === 'https://devagency.linkbuildinghq.com'){
        return 'dev';
    }else if(window.location.origin === 'https://tagency.linkbuildinghq.com'){
        return 'dev';
    }else{
        return 'local';
    }   
}

function getAPIURL(){
    if(window.location.origin === 'https://app.linkbuildinghq.com'){
        return 'https://api.linkbuildinghq.com';
    }else if(window.location.origin === 'https://devagency.linkbuildinghq.com'){
        return 'https://devagencyapi.linkbuildinghq.com';
    }else{
        return 'http://agency-portal.test';
    }   
}
 
function getPaypalKey(){
    if(window.location.origin === 'https://app.linkbuildinghq.com'){
        return 'AbGa_oJK7H0l0Hm8nn9MiNv2Ypmv-TB5Thu_HOmlrf8EaK55hOxRfEWQtNz8y02itPOsbfslfbOGx4SJ';
    }else if(window.location.origin === 'https://devagency.linkbuildinghq.com'){
        return 'AQJEhR0juT59MdVCAGRc88OZlNs9i1JVLzltE6bse7KIHCAnQVLMUR-O5Ckzz8TL4rbvUBiVBQn0580_';
    }else{
        return 'AQJEhR0juT59MdVCAGRc88OZlNs9i1JVLzltE6bse7KIHCAnQVLMUR-O5Ckzz8TL4rbvUBiVBQn0580_';
    }   
}

function getStripeKey(){
    if(window.location.origin === 'https://app.linkbuildinghq.com'){
        return 'pk_live_lRVPYGv2RwuLbJVVefpnuJDw';
    }else if(window.location.origin === 'https://devagency.linkbuildinghq.com'){
        return 'pk_test_51J83JOJJQBAkWgFpjlt4i7qxrImUB9KAWyvmyvnFMEdJ7JlUTf0lH6MdzDbUa99l1G1xuuMalJrhGkVTRov3fnck00GdEIjGj8';
    }else{
        // Publish key
        return 'pk_test_51J83JOJJQBAkWgFpjlt4i7qxrImUB9KAWyvmyvnFMEdJ7JlUTf0lH6MdzDbUa99l1G1xuuMalJrhGkVTRov3fnck00GdEIjGj8';
    }   
}

function getAdminURL(){
    if(window.location.origin === 'https://app.linkbuildinghq.com'){
        return 'https://admin.linkbuildinghq.com';
    }else if(window.location.origin === 'https://devagency.linkbuildinghq.com'){
        return 'https://dev.linkbuildinghq.com';
    }else{
        return 'http://pnc-portal.test';
    }   
}

function getAppURL(){
    if(window.location.origin === 'https://app.linkbuildinghq.com'){
        return 'https://app.linkbuildinghq.com';
    }else if(window.location.origin === 'https://devagency.linkbuildinghq.com'){
        return 'https://devagency.linkbuildinghq.com';
    }else{
        return 'http://localhost:8080';
    }   
}

const urls = {
    appStatus:  getAppStatus(),
    baseUrl:  getAPIURL(),
    appUrl:  getAppURL(),
    paypalKey:  getPaypalKey(),
    adminUrl:  getAdminURL(),
    stripeKey:  getStripeKey(),
}   

export default urls;

function numberFormatter(value,type) {
    if(type == 'currency'){
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    }else {
        return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
    }
}

export function numberFormat(value,type) {
    return numberFormatter(value,type);
}