<template>
    <div class="payment-detail-box">
        <form>
            <b-row>
            <div class="detail-box" :class="{ active: detailBoxBalance }">
                <b-row>
                <b-col md="12">
                    <div class="payment-heading">Balance Amount</div>
                    <div class="form-group">
                    <b-form-input
                        v-model="form.amountAdd"
                        type="text"
                        placeholder="Enter the amount you want to add"
                        required
                    ></b-form-input>
                    <span
                        v-if="errorsBalance.balance"
                        class="invalid-feedback"
                        style="display: block"
                        role="alert"
                    >
                        <strong>{{ errorsBalance.balance[0] }}</strong>
                    </span>
                    </div>
                </b-col>
                <template v-if="balanceNewCard">
                    <b-col md="12">
                    <div class="method-heading">Billing Details</div>
                    <div class="form-group">
                        <b-form-input
                        v-model="form.address"
                        type="text"
                        placeholder="Address*"
                        required
                        ></b-form-input>
                        <span
                        v-if="errorsBalance.address"
                        class="invalid-feedback"
                        style="display: block"
                        role="alert"
                        >
                        <strong>{{
                            form.address.length > 0
                            ? ""
                            : errorsBalance.address[0]
                        }}</strong>
                        </span>
                    </div>
                    </b-col>
                    <b-col md="6">
                    <div class="form-group">
                        <b-form-select
                        v-model="form.country"
                        @change="getStates($event)"
                        required
                        >
                        <option value="null" disabled hidden>
                            Select Country*
                        </option>
                        <option
                            v-for="(pd, k) in countries"
                            :key="k"
                            :value="pd.id"
                        >
                            {{ pd.name }}
                        </option>
                        </b-form-select>
                        <span
                        v-if="errorsBalance.country"
                        class="invalid-feedback"
                        style="display: block"
                        role="alert"
                        >
                        <strong>{{
                            form.country != null
                            ? ""
                            : errorsBalance.country[0]
                        }}</strong>
                        </span>
                    </div>
                    </b-col>
                    <b-col md="6">
                    <div class="form-group">
                        <b-form-select v-model="form.state" required>
                        <option value="null" disabled hidden>
                            Select States*
                        </option>
                        <option
                            v-for="(pd, k) in states"
                            :key="k"
                            :value="pd.id"
                        >
                            {{ pd.name }}
                        </option>
                        </b-form-select>
                        <span
                        v-if="errorsBalance.state"
                        class="invalid-feedback"
                        style="display: block"
                        role="alert"
                        >
                        <strong>{{
                            form.state != null ? "" : errorsBalance.state[0]
                        }}</strong>
                        </span>
                    </div>
                    </b-col>
                    <b-col md="6">
                    <div class="form-group">
                        <b-form-input
                        v-model="form.city"
                        type="text"
                        placeholder="City*"
                        required
                        ></b-form-input>
                        <span
                        v-if="errorsBalance.city"
                        class="invalid-feedback"
                        style="display: block"
                        role="alert"
                        >
                        <strong>{{
                            form.city.length > 0 ? "" : errorsBalance.city[0]
                        }}</strong>
                        </span>
                    </div>
                    </b-col>
                    <b-col md="6">
                    <div class="form-group">
                        <b-form-input
                        v-model="form.zip_code"
                        type="tel"
                        placeholder="Zip Code*"
                        required
                        ></b-form-input>
                        <span
                        v-if="errorsBalance.zip_code"
                        class="invalid-feedback"
                        style="display: block"
                        role="alert"
                        >
                        <strong>{{
                            form.zip_code.length > 0
                            ? ""
                            : errorsBalance.zip_code[0]
                        }}</strong>
                        </span>
                    </div>
                    </b-col>
                    <b-col md="12" v-if="SelectedMethodBalance == 'new_card'">
                    <div
                        class="check-box-text"
                    >
                        <b-form-checkbox
                        id="checkbox-3"
                        v-model="useProfileAddressCheckedBalance"
                        name="checkbox-3"
                        @change="getProfileAddress"
                        >
                        Use Profile Address
                        </b-form-checkbox>
                    </div>
                    </b-col>
                </template>
                </b-row>
            </div>
            <b-col md="12">
                <div
                class="paypal-box-sec"
                :class="{ active: !payPalBoxBalance }"
                >
                <div
                    class="detail-box"
                    :class="{ active: !detailBoxBalance }"
                >
                    <b-row>
                    <b-col md="12">
                        <div class="payment-heading">Balance Amount</div>
                        <div class="form-group">
                        <b-form-input
                            v-model="form.amountAdd"
                            type="text"
                            placeholder="Enter the amount you want to add"
                            required
                        ></b-form-input>
                        <span
                            v-if="errors.balanceAmount"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                        >
                            <strong>{{ errors.balanceAmount }}</strong>
                        </span>
                        </div>
                    </b-col>
                    </b-row>
                </div>
                </div>
            </b-col>
            <div class="method-heading">Payment Method</div>

            <div class="card-list">
                <ul>
                <li>
                    <input
                    type="radio"
                    id="6"
                    name="radio-group1"
                    v-model="methodBalance"
                    value="card"
                    @click="balanceListFunction(6)"
                    :class="{ active: balanceText == 6 }"
                    />
                    <label
                    for="6"
                    @click="balanceListFunction(6)"
                    :class="{ active: balanceText == 6 }"
                    ><img src="@/assets/common/images/cards.png"
                    /></label>
                </li>
                <li>
                    <input
                    type="radio"
                    id="7"
                    name="radio-group1"
                    v-model="methodBalance"
                    value="paypal"
                    @click="balanceListFunction(7)"
                    :class="{ active: balanceText == 7 }"
                    />
                    <label
                    for="7"
                    @click="balanceListFunction(7)"
                    :class="{ active: balanceText == 7 }"
                    ><img src="@/assets/common/images/pp-logo-150px.png"
                    /></label>
                </li>
                </ul>
            </div>

            <div class="detail-box" :class="{ active: detailBoxBalance }">
                <b-row>
                <b-col md="12">
                    <div class="save-card-btns">
                    <div class="toggle-box">
                        <div
                        class="card-text"
                        @click="cardBalanceFunction(3)"
                        :class="{ active: balanceText == 3 }"
                        >
                        New Card
                        </div>
                        <div
                        class="card-text"
                        @click="cardBalanceFunction(4)"
                        :class="{ active: balanceText == 4 }"
                        >
                        Saved Card
                        </div>
                    </div>
                    <div
                        class="check-box-text"
                        @click="cardBalanceFunction(3)"
                        :class="{ active: balanceText == 3 }"
                    >
                        <b-form-checkbox
                        id="checkbox-1"
                        v-model="saveCheckBalance"
                        name="checkbox-1"
                        value="save_card"
                        checked="save_card"
                        unchecked-value="no"
                        >
                        Save this card
                        </b-form-checkbox>
                    </div>
                    </div>
                    <span
                    v-if="errorsBalance.card"
                    class="invalid-feedback"
                    style="display: block"
                    role="alert"
                    >
                    <strong>{{ errorsBalance.card[0] }}</strong>
                    </span>
                </b-col>
                <b-col md="12">
                    <div
                    class="card-sec-1"
                    @click="cardBalanceFunction(3)"
                    :class="{ active: balanceText == 3 }"
                    >


                     <!-- Stripe 3D -->
                     <StripeElements
              v-if="stripeLoaded"
              v-slot="{ elements, instance }"
              ref="elms"
              :stripe-key="stripeKey"
              :instance-options="instanceOptions"
              :elements-options="elementsOptions"
          >
              <StripeElement
                ref="card"
                :elements="elements"
                :instance="instance"
                :options="cardOptions"
              />
          </StripeElements>


                    <!-- <b-row class="card-field-1">
                        <b-col md="9">
                        <div class="form-group">
                            <b-form-input
                            v-model="form.cardNumber"
                            type="text"
                            placeholder="Card number"
                            required
                            ></b-form-input>
                        </div>
                        </b-col>
                        <b-col md="2">
                        <div class="form-group">
                            <b-form-input
                            v-model="form.cardMM"
                            type="text"
                            maxlength="2"
                            placeholder="MM /"
                            @keypress="filterNumeric"
                            required
                            ></b-form-input>
                        </div>
                        <div class="form-group">
                            <b-form-input
                            v-model="form.cardYY"
                            type="text"
                            maxlength="2"
                            placeholder="YY /"
                            @keypress="filterNumeric"
                            required
                            ></b-form-input>
                        </div>
                        <div class="form-group">
                            <b-form-input
                            v-model="form.cardCVC"
                            type="text"
                            maxlength="4"
                            placeholder="CVV"
                            @keypress="filterNumeric"
                            required
                            ></b-form-input>
                        </div>
                        </b-col>
                    </b-row>

                    <b-col md="12" v-if="errorsBalance">
                        <b-row>
                        <b-col md="9">
                            <span
                            v-if="errorsBalance.card_number"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                            >
                            <strong>{{
                                errorsBalance.card_number[0]
                            }}</strong>
                            </span>
                        </b-col>
                        <b-col md="3">
                            <span
                            v-if="errorsBalance.expiration_month"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                            >
                            <strong>{{
                                errorsBalance.expiration_month[0]
                            }}</strong>
                            </span>
                            <span
                            v-if="errorsBalance.expiration_year"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                            >
                            <strong>{{
                                errorsBalance.expiration_year[0]
                            }}</strong>
                            </span>
                            <span
                            v-if="errorsBalance.cvv"
                            class="invalid-feedback"
                            style="display: block"
                            role="alert"
                            >
                            <strong>{{ errorsBalance.cvv[0] }}</strong>
                            </span>
                        </b-col>
                        </b-row>
                    </b-col> -->
                    <b-col md="12" class="card-field-2">
                        <b-row>
                        <b-col md="6">
                            <div class="form-group">
                            <b-form-input
                                v-model="form.first_name"
                                type="text"
                                placeholder="First Name"
                                required
                            ></b-form-input>
                            <span
                                v-if="errorsBalance.first_name"
                                class="invalid-feedback"
                                style="display: block"
                                role="alert"
                            >
                                <strong>{{ errorsBalance.first_name[0] }}</strong>
                            </span>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <div class="form-group">
                            <b-form-input
                                v-model="form.last_name"
                                type="text"
                                placeholder="Last Name"
                                required
                            ></b-form-input>
                            <span
                                v-if="errorsBalance.last_name"
                                class="invalid-feedback"
                                style="display: block"
                                role="alert"
                            >
                                <strong>{{ errorsBalance.last_name[0] }}</strong>
                            </span>
                            </div>
                        </b-col>
                        </b-row>
                    </b-col>
                    </div>
                    <div
                    class="card-sec-1"
                    :class="{ active: balanceText == 4 }"
                    >
                    <b-row>
                        <template
                        v-for="existingCard in existingCardList"
                        :key="existingCard.id"
                        >
                        <b-col md="6">
                            <div class="save-card-box">
                            <div class="card-1"
                                @click="
                                getSelectedCardDetail(existingCard.id)
                                "
                            >
                                <input
                                type="radio"
                                :id="'balancetest' + existingCard.id"
                                v-model="cardBalance"
                                name="chip-group"
                                :value="existingCard.id"
                                />
                                <label :for="'balancetest' + existingCard.id">
                                <img
                                    src="@/assets/common/images/chip.svg"
                                />
                                <div class="stars">
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life mr-2"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life mr-2"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life"></i>
                                    <i class="fas fa-star-of-life mr-2"></i>
                                    {{ existingCard.last4 }}
                                </div>
                                </label>
                            </div>
                            </div>
                        </b-col>
                        </template>
                    </b-row>
                    </div>
                </b-col>
                <b-col md="12" v-if="SelectedMethodBalance == 'new_card'">
                    <button type="button" class="btn-1" @click="stripePaymentMethod()">
                    <i class="fas fa-lock"></i> Pay with Credit Card
                    </button>
                </b-col>
                <b-col md="12" v-if="SelectedMethodBalance == 'save_card'">
                    <button type="button" class="btn-1" @click="stripeSavePaymentMethod()">
                    <i class="fas fa-lock"></i> Pay with Credit Card
                    </button>
                </b-col>
                </b-row>
            </div>

            <div
                class="paypal-box-sec"
                :class="{ active: !payPalBoxBalance }"
            >
                <div
                    class="detail-box"
                    :class="{ active: !detailBoxBalance }"
                >
                <div
                    id="paypal-button-container-balance"
                    :class="{ active: paypalShowBalance == true }"
                ></div>
                </div>
            </div>
            </b-row>
        </form>
    </div>
</template>



<script>
import { loadStripe } from '@stripe/stripe-js'
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { useToast } from "vue-toastification";
const toast = useToast();
import url from "@/prod.js";
import jquery from "jquery";

export default {
    name: "AddBalanceComponent",
    components: {
        StripeElements,
        StripeElement
    },
    props: ["countries","existingCards"],
    watch: {
        existingCards: function (val) {
            this.existingCardList = val;
        }
    },
    beforeMount() {
        const stripePromise = loadStripe(this.stripeKey)
        stripePromise.then(() => {
        this.stripeLoaded = true
        })
    },
    mounted() {
        this.loadScript();
    },
    data() {
        return {
            paypal: url.paypalKey,
            methodBalance: "card",
            SelectedMethodBalance: "new_card",
            detailBoxBalance: true,
            payPalBoxBalance: false,
            cardBalance: "",
            saveCheckBalance: "no",
            existingCardBalance: "no",
            balanceText: 3,
            paypalShowBalance: false,
            balanceNewCard: true,
            errorsBalance: [],
            existingCardList: [],
            form: {
                card_number: "",
                amountAdd: "",
                expirationMM: "",
                expirationYear: "",
                cardCVC: "",
                cardNumber: "",
                first_name: "",
                last_name: "",
                address: "",
                zip_code: "",
                country: null,
                state: null,
                city: "",
            },
            states: [],
            errors: [],
            useProfileAddressCheckedBalance: false,
            stripeKey: url.stripeKey,
            instanceOptions: {},
            elementsOptions: {},
            cardOptions: {
                hidePostalCode: true
            },
            stripeLoaded: false,
            paymentIntent_id: '',
            paymentMethod_id: '',
            cardLast4: '',
        }
    },
    methods: {
        filterNumeric(event) { 
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']; 
            const keyPressed = event.key;
            
            if (!keysAllowed.includes(keyPressed)) {
                event.preventDefault();
            }
        },
        loadScript() {
            const recaptchaScript = document.createElement("script");
            recaptchaScript.setAttribute(
                "src",
                "https://www.paypal.com/sdk/js?client-id=" +
                this.paypal +
                "&currency=USD&disable-funding=credit,card"
            );
            document.head.appendChild(recaptchaScript);
        },
        paypalOptionBalance() {
            if (window.paypal) {
                var vm = this;
                window.paypal
                .Buttons({
                    style: {
                    shape: "rect",
                    color: "gold",
                    layout: "vertical",
                    label: "paypal",
                    size: "responsive",
                    tagline: "false",
                    },
                    onClick: function () {
                    if (vm.form.amountAdd == "") {
                        vm.errors["balanceAmount"] = "The balance field is required.";
                        return false;
                    }
                    },
                    // Sets up the transaction when a payment button is clicked
                    createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                        {
                            amount: {
                                value: Number(vm.form.amountAdd).toFixed(2), // Can also reference a variable or function
                            },
                        },
                        ],
                    });
                    },
                    // Finalize the transaction after payer approval
                    onApprove: (data, actions) => {
                    return actions.order.capture().then(function (orderData) {
                        const transaction =
                        orderData.purchase_units[0].payments.captures[0];
                        vm.payment_id = transaction.id;
                        vm.addBalance();
                    });
                    },
                    onError: function () {
                    if (!Number.isInteger(vm.form.amountAdd)) {
                        vm.errors["balanceAmount"] =
                        "The balance field must be a number.";
                    } else if (vm.form.amountAdd == "") {
                        vm.errors["balanceAmount"] = "The balance field is required.";
                    }
                    },
                })
                .render("#paypal-button-container-balance");
            }
        },
        async getStates(countryId) {
            this.$parent.$parent.loading = true;
            this.form.state = null;
            try {
                let res = await this.$store.dispatch(
                "Product/getStatesDataAction",
                countryId
                );
                this.states = res.states;
                this.$parent.$parent.loading = false;
            } catch (e) {
                this.$parent.$parent.loading = false;
            }
        },
        cardBalanceFunction(card) {
            this.errors = [];
            this.balanceText = card;
            if (this.balanceText == 4) {
                this.existingCardBalance = "yes";
                this.saveCardBalance = "no";
                this.SelectedMethodBalance = "save_card";
                this.saveCheckBalance = "no";
                this.balanceNewCard = true;
                if(this.cardBalance != ""){
                    this.getSelectedCardDetail(this.cardBalance)
                }
            } else {
                this.existingCardBalance = "no";
                this.SelectedMethodBalance = "new_card";
                this.errorsBalance["card"] = "";
                this.balanceNewCard = true;
            }
        },
        balanceListFunction(num) {
            if(this.cardText == 4 && num == 6){
                this.cardBalanceFunction(4);
            }
            if (num == 6) {
                this.detailBoxBalance = true;
                this.payPalBoxBalance = true;
                this.cardBalanceFunction(3);
            } else if (num == 7 && this.paypalShowBalance == false) {
                this.SelectedMethodBalance = "paypal";
                this.existingCardBalance = "no";
                this.saveCheckBalance = "no";
                this.form.cardNumber = "";
                this.form.first_name = "";
                this.form.last_name = "";
                this.form.cardMM = "";
                this.form.cardYY = "";
                this.form.cardCVC = "";
                this.detailBoxBalance = false;
                this.payPalBoxBalance = false;
                this.paypalShowBalance = true;

                this.paypalOptionBalance();
            } else {
                this.existingCardBalance = "no";
                this.saveCheckBalance = "no";
                this.SelectedMethodBalance = "paypal";
                this.detailBoxBalance = false;
                this.payPalBoxBalance = false;
            }
        },
        async getSelectedCardDetail(id) {
            this.$parent.$parent.loading = true;
            this.useProfileAddressCheckedBalance = false;
            try {
                let res = await this.$store.dispatch(
                "Product/getSelectedCardDataAction",
                id
                );
                if(res.country){
                    this.getStates(res.country);
                }
                this.form.address = res.line1;
                this.form.country = res.country;
                this.form.city = res.city;
                this.form.zip_code = res.postal_code;
                this.form.state = res.state;
                // this.$parent.$parent.loading = false;
            } catch (e) {
                this.$parent.$parent.loading = false;
            }
        },

        async getProfileAddress() {
            this.$parent.$parent.loading = true;
            if(this.useProfileAddressCheckedBalance){
                try {
                let res = await this.$store.dispatch(
                    "Profile/getProfileAddressDataAction"
                );
                this.form.address = res.user_address.address;
                this.form.city = res.user_address.city;
                this.form.country = res.user_address.country;
                if (this.form.country) {
                    this.getStates(this.form.country);
                    this.form.state = res.user_address.state;
                }
                    this.form.zip_code = res.user_address.postal_code;
                    this.$parent.$parent.loading = false;
                } catch (e) {
                    this.loading = false;
                }
            }else if(!this.useProfileAddressCheckedBalance && this.SelectedMethodBalance == 'save_card' && this.cardBalance != ""){
                this.getSelectedCardDetail(this.cardBalance)
            }else {
                this.form.address = "";
                this.form.city = "";
                this.form.country = null;
                this.form.state = null;
                this.form.zip_code = "";
            }
            this.$parent.$parent.loading = false;
        },

        async stripePaymentMethod() {
        this.$parent.$parent.loading = true;
        const { paymentMethod, error } = await this.$refs.elms.instance.createPaymentMethod({
            type: 'card',
            card: this.$refs.card.stripeElement,
            billing_details: {
                name: this.form.first_name + ' ' + this.form.last_name,
                address: {
                    line1: this.form.address,
                    city: this.form.city,
                    state: this.form.state,
                    postal_code: this.form.zip_code,
                },
                // email: this.userData.email,
            },
        });
        
        if (error) {
            this.$parent.$parent.loading = false;
            // document.getElementById('card-errors').textContent = error.message;
            toast.error(error.message, {
            timeout: 4000,
            });
            return;
        }

        try{
            // Send PaymentMethod ID to the server
            let responseData = await this.$store.dispatch(
            "Product/stripePaymentMethodAction",
            {
                'payment_method' : paymentMethod.id,
                'balance': this.form.amountAdd,
                'amount' : this.form.amountAdd,
                'name' : this.form.first_name + ' ' + this.form.last_name,
                'address' : this.form.address,
                'country' : this.form.country,
                'city' : this.form.city,
                'state' : this.form.state,
                'zip_code' : this.form.zip_code,
                'first_name' : this.form.first_name,
                'last_name' : this.form.last_name,
                'addBalance': true
                // 'email' : this.userData.email
            },
            {
                root: true,
            }
            );
            this.paymentMethod_id = paymentMethod.id;
            this.cardLast4 = paymentMethod['card']['last4'];
            this.paymentIntent_id = responseData.id;
            // console.log(responseData);
            this.handlePayment(responseData);
        } catch (e) {
            this.$parent.$parent.loading = false;
            this.errorsBalance = [];
            if (e.response.data.errors) {
                this.errorsBalance = e.response.data.errors;
            } else {
                toast.error(e.response.data.message, {
                timeout: 4000,
                });
            }
        }
        },
        async stripeSavePaymentMethod() {
        this.$parent.$parent.loading = true;
        try{
            // Send PaymentMethod ID to the server
            let responseData = await this.$store.dispatch(
            "Product/stripeSavePaymentMethodAction",
            {
                'balance': this.form.amountAdd,
                'amount' : this.form.amountAdd,
                'address' : this.form.address,
                'country' : this.form.country,
                'city' : this.form.city,
                'state' : this.form.state,
                'zip_code' : this.form.zip_code,
                'card': this.cardBalance,
                'addBalance': true
            },
            {
                root: true,
            }
            );
            
            this.paymentMethod_id = responseData.payment_method;
            this.paymentIntent_id = responseData.id;
            this.handlePayment(responseData);
        } catch (e) {
            this.$parent.$parent.loading = false;
            this.errorsBalance = [];
            if (e.response.data.errors) {
                this.errorsBalance = e.response.data.errors;
            } else {
                toast.error(e.response.data.message, {
                timeout: 4000,
                });
            }
        }
        },
        async handlePayment(paymentIntent) {
            if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_source_action') {
                // Confirm the payment using 3D Secure
                const { error, paymentIntent: confirmedPaymentIntent } = await this.$refs.elms.instance.confirmCardPayment(paymentIntent.client_secret);
                
                if (error) {
                this.$parent.$parent.loading = false;
                toast.error(error.message, {
                    timeout: 4000,
                });
                } else if (confirmedPaymentIntent.status === 'succeeded') {
                this.addBalance();
                }
            } else if (paymentIntent.status === 'succeeded') {
                this.addBalance();
            }
        },
        
        async addBalance() {
            this.errorsBalance["balance"] = [];
            try {
                this.$parent.$parent.loading = true;
                var vm = this;

                let res = await this.$store.dispatch(
                "Product/addBalanceAction",
                {
                    balance: vm.form.amountAdd,
                    method: vm.SelectedMethodBalance,
                    saveCard: vm.saveCheckBalance,
                    existingCard: vm.existingCardBalance,
                    card: vm.cardBalance,
                    payment_id: vm.payment_id,
                    first_name: vm.form.first_name,
                    last_name: vm.form.last_name,
                    card_name: vm.form.first_name + ' ' + vm.form.last_name,
                    card_number: vm.form.cardNumber,
                    expiration_month: vm.form.cardMM,
                    expiration_year: vm.form.cardYY,
                    cvv: vm.form.cardCVC,
                    address: vm.form.address,
                    country: vm.form.country,
                    state: vm.form.state,
                    city: vm.form.city,
                    zip_code: vm.form.zip_code,
                    paymentIntentId: vm.paymentIntent_id,
                    paymentMethodId: vm.paymentMethod_id,
                    last4: vm.cardLast4,
                },
                {
                    root: true,
                }
                );

                if (res.success) {
                    this.existingCardList = res.data;
                    this.$parent.$parent.loading = false;
                    jquery(".btn-close").trigger("click");
                    toast.success(res.message, { timeout: 3000 });
                    for (let key in this.form) {
                        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
                            this.form[key] = '';
                        }
                    }
                    this.form.country = null;
                    this.form.state = null;
                    this.cardBalance = "";
                    this.methodBalance = "card";
                    this.balanceListFunction(6);
                    this.cardBalanceFunction(3);
                    this.errorsBalance = [];
                    this.form.amountAdd = "";
                    this.form.address = "";
                    this.form.city = "";
                    this.form.country = null;
                    this.form.state = null;
                    this.form.zip_code = "";
                    this.useProfileAddressCheckedBalance = false;
                }
            } catch (error) {
                this.useProfileAddressCheckedBalance = false;
                this.$parent.$parent.loading = false;
                this.errorsBalance = [];
                if (error.response.data.errors) {
                    this.errorsBalance = error.response.data.errors;
                } else {
                    toast.error(error.response.data.message, {
                        timeout: 4000,
                    });
                }
            }
        },
    }
}
</script>
